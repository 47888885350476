import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as updateConfigurationActions from '../../actions/updateConfigurationActions'
import * as configurationActions from '../../actions/configurationActions'
import * as uiActions from '../../actions/uiActions'
import * as patternsService from '../../services/patterns.service'
import * as _ from 'lodash'
import { isSmartphone } from '../../services/domFunctions'
import { Carousel } from '../../components/carousel'
import { OvalAction } from '../../components/OvalAction'
import { Button } from 'reactstrap'
import history from '../../history'
import MediaQuery from 'react-responsive/src'
import FLoader from '../../../src/components/FLoader.js'
// import { getFamilySession } from '../../services/utils.service';
import { gtmFamily } from '../../services/gtmFamily';
import { Modal } from '../../components/Modal';

const limit = 18
const defaultKeyWords = [
  'Animal',
  'Végétal',
  'Paysage',
  'Personnage',
  'Objet',
  'Symbole',
  'Accessoires'
]
const defaultState = {
  window: true,
  selectedPattern: null,
  query: '',
  keywords: [], // C'est la première recherche qui initialise les keywords par défaut
  patterns: [],
  loaded: [],
  total: 0,
  resetCarousel: false,
  hover: false,
  isSearch: false,
  displayMsgConfigMax: false
}

/**
 * Modal permettant de rechercher et choisir un motif à ajouter sur la face courante
 */
class FpatternParamsComponent extends Component {
  state = defaultState
  searchInput

  async componentDidMount() {
    if (this.props.configurator.configuration.designType === 'B') {
      document.documentElement.style.setProperty('--fcblue', '#3568ba')
    }

    this.search('')
    if (isSmartphone()) {
      // en Mode smartphone on n'ouvre pas les paramètres par défaut
      this.setState({ window: false })
    }
    setTimeout(() => {
      if ( window.innerWidth <= 768 ) {
        const list = this.state.patterns.map(pattern => {
          return {
            reference: pattern.reference,
            type: pattern.kind,
            description: pattern.description
          }
        });
        gtmFamily().customizeConfigurator(
          "configurator.filter",
          "liste motifs",
          {
            type: "motif",
            query: "type",
            filter: "Tous"
          },
          list
        )
      }
    }, 400)
  }

  componentDidUpdate(prevProps, prevState) {
    // GTM
    if (this.state.isSearch && prevState.lastQuery != undefined && prevState.lastQuery !== this.state.lastQuery ) {
      const list = this.state.patterns.map(p => {
        return {
          reference: p.reference,
          type: p.kind,
          description: p.description
        }
      })
      gtmFamily().customizeConfigurator(
        "configurator.search",
        "liste motifs",
        {
          type: "motif",
          query: "search",
          filter: this.state.lastQuery
        },
        list
      )
      this.setState({isSearch: false})
    }
    // end GTM
  }

  search = _.debounce(() => {
    // On vide les résultats quand la recherche commence.
    // Cela provoque le scroll vers le haut de la liste et évite qu'un utilisateur
    // clique sur un motif qui vient d'être remplacé par le chargement des résultats
    // de recherche.
    // this.setState({ patterns: [] });
    return this.doSearch(this.state.query, [])
  }, 300)

  onClickKeyword = (keyword) => {
    this.setState({ query: keyword, selectedPattern: null }, this.search)
    // GTM
    setTimeout(() => {
      const list = this.state.patterns.map(pattern => {
        return {
          reference: pattern.reference,
          type: pattern.kind,
          description: pattern.description
        }
      });
      gtmFamily().customizeConfigurator(
        "configurator.filter",
        "liste motifs",
        {
          type: "motif",
          query: "type",
          filter: keyword
        },
        list
      )
    }, 400);
    // end GTM
  }

  doSearch = (query, patterns) => {
    patternsService
      .getPatterns(query, patterns.length, limit, this.props.user.family)
      .then((result) => {
        let newPatterns = result.patterns.list
        if (this.state.selectedPattern) {
          newPatterns = newPatterns.filter(
            (elt) => elt.reference !== this.state.selectedPattern.reference
          )
          newPatterns = [this.state.selectedPattern, ...newPatterns]
        }
        this.setState({
          lastQuery: query,
          resetCarousel: false,
          keywords: result.keywords.length ? result.keywords : defaultKeyWords,
          patterns: patterns.concat(newPatterns),
          total: result.patterns.total
        })
      })
  }

  onSelectPattern = (pattern, index) => {
    if (isSmartphone()) {
      this.onPickPattern(pattern)
    } else {
      this.setState({
        query: pattern.keywords,
        resetCarousel: true,
        selectedPattern: pattern,
        patterns: [pattern]
      })
      this.search()
    }
  }

  onPickPattern = (pattern) => {
    const { configurator: current, user: { paramsFamilyConfigMax } } = this.props;
    if(current.configuration.patterns.length > parseInt(paramsFamilyConfigMax.FAMILY_CONFIG_MAX_MOTIFS) - 1) {
      this.setState({displayMsgConfigMax: true})
      isSmartphone() && this.props.close()
    } else {
      // GTM
      const patternMotif = {
        reference: pattern.reference,
        type: pattern.kind,
        description: pattern.description,
      }
      gtmFamily().customizeConfigurator(
        "configurator.customize",
        "liste motifs",
        {
          type: "motif",
          query: "réference",
          filter: pattern.reference
        },
        false,
        patternMotif
      )
      // end GTM
      const face = _.find(this.props.configurator.options.faces, 'default')
      const { updateActions } = this.props
      updateActions.addPattern(face, pattern)
      if (isSmartphone()) {
        this.props.close();
        //this.setState({ window: false })
      }
    }
  }

  seeSimilarPatterns = (pattern) => {
    this.setState({ query: pattern.keywords })
    // this.state.query = pattern.keywords;
    this.search()
  }

  searchBoxChange = (event) => { 
    this.setState({ isSearch: true, query: event.target.value }, this.search)
  }
  onLoad = (index) => {
    let cloneLoaded = [...this.state.loaded]
    cloneLoaded[index] = true
    this.setState({ loaded: cloneLoaded })
  }
  
  loadingState = (index) => {
    return this.state.loaded[index]
  }
  renderPattern = (pattern, index) => {
    const { displayMsgConfigMax } = this.state
    const { user: { paramsFamilyConfigMax } } = this.props;
    const {mainColor, fontColor} = this.props.adminConfig.config
    return (
      <div
        className='Fcarousel'
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        {pattern && (
          <div className='FPatternCarousel'>
            <span className='helper'></span>
            <img src={pattern.images.render} alt='Motifs' /> /
            {this.state.hover && (
              <OvalAction
                action={() => this.onPickPattern(pattern)}
                text='Ajouter ce motif'
                color='Blue'
                addClass={`SelectPattern`}
                colorConfig={{mainColor, fontColor}}
              />
            )}
          </div>
        )}
        <Modal
          isOpen={displayMsgConfigMax}
          onRequestClose={() => this.setState({displayMsgConfigMax: false})}
          className="modal-display-msg"
        >
          <p>{`Vous avez atteint le nombre maximum de motifs, veuillez supprimer un des ${paramsFamilyConfigMax.FAMILY_CONFIG_MAX_MOTIFS} motifs pour pouvoir en ajouter un autre.`}</p>
        </Modal>
      </div>
    )
  }

  render() {
    const { client, reference, designType } =
      this.props.configurator.configuration
    return (
      <div className='TopFpattern'>
        <div
          className={`FpatternParams ${
            designType === 'B' ? 'FpatternParamsB' : ''
          }`}
        >
          <MediaQuery maxWidth={768}>
            <button className='closeIcon' onClick={() => this.props.close()}>
              <svg
                width='14'
                height='14'
                viewBox='0 0 14 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
                  fill='#1F1F1F'
                />
              </svg>
            </button>
          </MediaQuery>

          {(!designType || designType !== 'B') && (
            <i
              className='icon material-icons'
              onClick={() => this.setState({ window: false })}
            >
              &#xE5CD;
            </i>
          )}
          <div
            className={
              designType && designType === 'B' ? 'FpatterParams-title-b' : ''
            }
          >
            <div className='title'>Ajoutez un motif</div>
            <div className='subTitle'>parmi plus de 1000 disponibles !</div>
          </div>
          <div className='FpatternContainer'>
            <form
              className='FpatternInput'
              noValidate
              action=''
              onSubmit={(event) => {
                event.preventDefault()
                this.search()
              }}
            >
              <input
                ref={(ref) => (this.searchInput = ref)}
                type='text'
                placeholder='Recherche'
                value={this.state.query}
                onChange={this.searchBoxChange}
              />
              <i className='icon material-icons'>search</i>
            </form>
            <div className='Keywords'>
              {this.state.keywords.map((keyword) => (
                <button
                  key={keyword}
                  onClick={() => this.onClickKeyword(keyword)}
                >
                  {keyword}
                </button>
              ))}
            </div>
            {this.state.selectedPattern !== null && !this.state.resetCarousel && (
              <div>
                <Carousel
                  slidesData={this.state.patterns}
                  slideToShow={0}
                  renderSlide={this.renderPattern}
                />
                <div className='similarPattern'>Motifs similaires</div>
              </div>
            )}
            <div className='imagePatterns'>
              {this.state.patterns.map((pattern, index) => (
                <div
                  key={index}
                  className='FpatternCard'
                  onClick={() => this.onSelectPattern(pattern, index)}
                >
                  {this.loadingState() && <FLoader size="S" />}
                  <img
                    className='FpatternImage'
                    onLoad={() => this.onLoad(index)}
                    alt='Motif'
                    src={pattern.images.drawing}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const FpatternParams = connect(
  (state) => ({
    configurator: state.configurator.current,
    patternToUpdate: state.ui.Fpattern,
    user: state.user,
    adminConfig: state.adminConfig
  }),
  (dispatch) => ({
    actions: bindActionCreators(configurationActions, dispatch),
    updateActions: bindActionCreators(updateConfigurationActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch)
  })
)(FpatternParamsComponent)
